import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"



export default class ReferanslarPage extends React.Component {
  render() {
    const data = this.props.data

        //control
        const logocontrol = data.logocontrol.childImageSharp.fluid
        const control1 = data.control1.childImageSharp.fluid
        const control2 = data.control2.childImageSharp.fluid
        const control3 = data.control3.childImageSharp.fluid
        //Anadolu
        const logoanadolu = data.logoanadolu.childImageSharp.fluid
        const anadoluses1 = data.anadoluses1.childImageSharp.fluid
        const anadoluses2 = data.anadoluses2.childImageSharp.fluid
        const anadoluses3 = data.anadoluses3.childImageSharp.fluid
        //Effect
        const logoeffect = data.logoeffect.childImageSharp.fluid
        const effect1 = data.effect1.childImageSharp.fluid
        const effect2 = data.effect2.childImageSharp.fluid
        const effect3 = data.effect3.childImageSharp.fluid
        //gaye
        const logogaye = data.logogaye.childImageSharp.fluid
        const gaye1 = data.gaye1.childImageSharp.fluid
        const gaye2 = data.gaye2.childImageSharp.fluid
        const gaye3 = data.gaye3.childImageSharp.fluid
        //bula
        const logobula = data.logobula.childImageSharp.fluid
        const bulass1 = data.bulass1.childImageSharp.fluid
        const bulass2 = data.bulass2.childImageSharp.fluid
        const bulass3 = data.bulass3.childImageSharp.fluid
        //erteklaw
        const logoertek = data.logoertek.childImageSharp.fluid
        const ertekss1 = data.ertekss1.childImageSharp.fluid
        const ertekss2 = data.ertekss2.childImageSharp.fluid
        const ertekss3 = data.ertekss3.childImageSharp.fluid
        //erteklaw
        const logocan = data.logocan.childImageSharp.fluid
        const canss1 = data.canss1.childImageSharp.fluid
        const canss2 = data.canss2.childImageSharp.fluid
        const canss3 = data.canss3.childImageSharp.fluid
        //karayaka
        const logokarayaka = data.logokarayaka.childImageSharp.fluid
        const karayakass1 = data.karayakass1.childImageSharp.fluid
        const karayakass2 = data.karayakass2.childImageSharp.fluid
        const karayakass3 = data.karayakass3.childImageSharp.fluid
        //mesareklam
        const logomesa = data.logomesa.childImageSharp.fluid
        const mesass1 = data.mesass1.childImageSharp.fluid
        const mesass2 = data.mesass2.childImageSharp.fluid
        const mesass3 = data.mesass3.childImageSharp.fluid
        //motorluperde
        const logomotor = data.logomotor.childImageSharp.fluid
        const motorluss1 = data.motorluss1.childImageSharp.fluid
        const motorluss2 = data.motorluss2.childImageSharp.fluid
        const motorluss3 = data.motorluss3.childImageSharp.fluid
        //ofistabela
        const logoofis = data.logoofis.childImageSharp.fluid
        const ofistabelass1 = data.ofistabelass1.childImageSharp.fluid
        //sahne
        const sahness1 = data.sahness1.childImageSharp.fluid
        const sahness2 = data.sahness2.childImageSharp.fluid
        const sahness3 = data.sahness3.childImageSharp.fluid
        //motorluperde
        const logoturkbil = data.logoturkbil.childImageSharp.fluid
        const turkbilss1 = data.turkbilss1.childImageSharp.fluid
        const turkbilss2 = data.turkbilss2.childImageSharp.fluid
        const turkbilss3 = data.turkbilss3.childImageSharp.fluid
        //motorluperde
        const logowinsa = data.logowinsa.childImageSharp.fluid
        const winsass1 = data.winsass1.childImageSharp.fluid
        const winsass2 = data.winsass2.childImageSharp.fluid
        const winsass3 = data.winsass3.childImageSharp.fluid

        const pagebgref = data.pagebgref.childImageSharp.fluid
    return (
  <Layout pageTitle="Referanslar">
  <SEO title="Web Tasarım - E-Ticaret - Kurumsal Kimlik" keywords={[`istanbul Web Tasarım`, `Referanslar`, `Web Tasarım Referanslar`]} description="100lerce mutlu web tasarım müşterisi ile istanbul Web Tasarım sizlerle" />

    <section className="bginpage">
    <Img fluid={pagebgref}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Referanslar"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
        Referanslar
             </h1>
             <p>İstanbul Web Tasarım 2012 'den bu yana 1000'den fazla müşteriyi potansiyel müşterileri ile buluşturdu.Kaliteli , kalıcı , estetik , şık ve kullanışlı tasarım anlayaşı ile oluşturduğumuz yazılımlarımız ile tanışmaya davetlisiniz.Tam referans listesi için mail atınız.</p>
      </div>  </Col>
        </Row>
        </Container>
  </section>

  			        <section  className="section referanslar bg-parallax br-bot-de">
                <Container>
			            <Row>
			            <Col sm="12" md={{ size: 8, offset: 2 }}>
			            <h1 className="font-size-normal text-center"><small>Aramıza Yeni Katılan Web Tasarım , E-Ticaret , Kurumsal Kimlik Projeleri</small> Web Tasarım Referans Projeler</h1>
                  <p className="text-center">İstanbul Web Tasarım olarak yalnızca İstanbul odaklı değiliz ! Türkiyenin dörtbir yanında kurumsal firmalara, kurumsal kaliteli web tasarım, e-ticaret,arama motoru optimizasyonu projelerimiz ile buluşturuyoruz.</p>
			  </Col>
			            </Row>

                  <div className="opacityblock">
                 <Row>
                   <Col md="5">
                     <div className="numberheighlight"><Img fluid={logocontrol} />
                     </div>
                     <h4>Control Teknoloji ve Sanayi</h4>
                     <p className="alpha-color">Control Teknoloji ve Sanayi A.Ş için hazırladığımız Otomasyon sistemi ile entegre özel dinamik yazılım.Dinamik kodlama yapısı ,temiz html5 ve css3 kalıbı ile yazıldı.</p>
                      <p>Adres : www.Control.Com.Tr<br />
                    Firma Lokasyonu : Mersin</p>
                   </Col>
                   <Col md="7">
                     <Row>

                       <Col lg="4" md="12" sm="12" xs="12">
                       <div className="refimg">
                         <figure>
                          <Img fluid={control1} alt="Control Teknoloji Web Tasarım"/>
                         </figure>
                       </div>
                       </Col>
                       <Col lg="4" md="6" sm="6" xs="6">
                       <div className="refimg">
                         <figure>
                          <Img fluid={control2} alt="Control Teknoloji Web Tasarım"/>
                         </figure>
                       </div>
                       </Col>
                       <Col lg="4" md="6" sm="6" xs="6">
                       <div className="refimg">
                         <figure>
                         <Img fluid={control3} alt="Control Teknoloji Web Tasarım" />
                         </figure>
                       </div>
                       </Col>
                     </Row>
                   </Col>
                   </Row>
       </div>
           <div className="opacityblock">
                 <Row>
                 <Col md="5">
                     <div className="numberheighlight"><Img fluid={logoanadolu}  alt="Anadolu Müzik Ses" />
                     </div>
                     <h4>Anadolu Ses , Işık ve Görüntü Sistemleri</h4>
                     <p className="alpha-color">Anadolu Ses Işık ve Görüntü Sistemleri için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
                  <p>Adres : www.anadoluses.com<br />
                   Firma Lokasyonu : Ankara</p>
                    </Col>
                   <Col md="7">
                     <Row>
                       <Col lg="4" sm="12" xs="12">

                       <div className="refimg">
                         <figure>
                          <Img fluid={anadoluses1} alt="Anadolu Müzik Ses Web Tasarım" />
                         </figure>
                       </div>
                       </Col>
                        <Col lg="4" md="6" sm="6" xs="6">

                       <div className="refimg">
                         <figure>
                        <Img fluid={anadoluses2} alt="Anadolu Müzik Ses Web Tasarım"/>
                         </figure>
                       </div>
                       </Col>
                       <Col lg="4" md="6" sm="6" xs="6">
                       <div className="refimg">
                         <figure>
                          <Img fluid={anadoluses3} alt="Anadolu Müzik Ses Web Tasarım" />
                         </figure>
                       </div>
                       </Col>

                     </Row>
                   </Col>
                   </Row>
                   </div>
                     <div className="opacityblock">
                 <Row>
                   <Col md="5">
                     <div className="numberheighlight"> <Img fluid={logogaye}  alt="Gaye Dekor Logo" />
                     </div>
                     <h4>Gaye Dekor</h4>
                     <p className="alpha-color">Ankara Çay Yolu Dekorasyon Gaye Dekor için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.Referans projeleri için galeri vb özellikler bulunmaktadır.</p>
                  <p>Adres : www.gayedekor.com <br />
                   Firma Lokasyonu : Ankara</p> </Col>
                   <Col md="7">
                     <Row>

                       <Col lg="4" sm="12" xs="12">
                       <div className="refimg">
                         <figure>
                           <Img fluid={gaye1} alt="Gaye Dekor Web Tasarım"/>
                         </figure>
                       </div>
                       </Col>
                       <Col lg="4" md="6" sm="6" xs="6">
                       <div className="refimg">
                         <figure>
                          <Img fluid={gaye2} alt="Gaye Dekor Web Tasarım"/>
                         </figure>
                       </div>
                       </Col>
                       <Col lg="4" md="6" sm="6" xs="6">
                       <div className="refimg">
                         <figure>
                         <Img fluid={gaye3} alt="Gaye Dekor Web Tasarım"/>
                         </figure>
                       </div>
                       </Col>
                     </Row>
                   </Col>
                     </Row>
       </div>


        <div className="opacityblock">
    <Row>
      <Col md="5">
        <div className="numberheighlight"><Img fluid={logoeffect} alt="Effect Aluminyum Logo"/>
        </div>
        <h4>Effect Aluminyum</h4>
        <p className="alpha-color">Effect Aluminyum için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
  <p>Adres : www.effectaluminyum.com<br />
  Firma Lokasyonu : Ankara</p>    </Col>
      <Col md="7">
        <Row>

          <Col lg="4" sm="12" xs="12">
          <div className="refimg">
            <figure>
            <Img fluid={effect1}  alt="Effect Aluminyum Web Tasarım"/>
            </figure>
          </div>
          </Col>
          <Col lg="4" md="6" sm="6" xs="6">
          <div className="refimg">
            <figure>
          <Img fluid={effect2} alt="Effect Aluminyum Web Tasarım"/>
            </figure>
          </div>
          </Col>
          <Col lg="4" md="6" sm="6" xs="6">
          <div className="refimg">
            <figure>
          <Img fluid={effect3} alt="Effect Aluminyum Web Tasarım"/>
            </figure>
          </div>
          </Col>
        </Row>
      </Col>
        </Row>
 </div>

         <div className="opacityblock">
     <Row>
       <Col md="5">
         <div className="numberheighlight"> <Img fluid={logobula} alt="Bula Logo" className="" />
         </div>
         <h4>Bula İnşaat</h4>
         <p className="alpha-color">Bula İnşaat için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
   <p>Adres : www.bula.com.tr<br />
   Firma Lokasyonu : Ankara</p>    </Col>
       <Col md="7">
         <Row>

           <Col md="4">
           <div className="refimg">
             <figure>
               <Img fluid={bulass1} alt="İnşaat Web Tasarım 1" className="" />
             </figure>
           </div>
           </Col>
           <Col md="4" sm="6" xs="6">
           <div className="refimg">
             <figure>
               <Img fluid={bulass2} alt="İnşaat Web Tasarım 2" className="" />
             </figure>
           </div>
           </Col>
           <Col md="4" sm="6" xs="6">
           <div className="refimg">
             <figure>
             <Img fluid={bulass3} alt="İnşaat Web Tasarım 3" className="" />
             </figure>
           </div>
           </Col>
         </Row>
       </Col>
         </Row>
  </div>



          <div className="opacityblock">
      <Row>
        <Col md="5">
          <div className="numberheighlight"> <Img fluid={logocan} alt="Can Saygil Logo" className="" />
          </div>
          <h4>Can Saygil</h4>
          <p className="alpha-color">Can Saygil için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
    <p>Adres : www.cansaygil.com<br />
    Firma Lokasyonu : İstanbul</p>    </Col>
        <Col md="7">
          <Row>

            <Col md="4">
            <div className="refimg">
              <figure>
                <Img fluid={canss1} alt="Bireysel Web Tasarım 1" className="" />
              </figure>
            </div>
            </Col>
            <Col md="4" sm="6" xs="6">
            <div className="refimg">
              <figure>
                <Img fluid={canss2} alt="Bireysel Web Tasarım 2" className="" />
              </figure>
            </div>
            </Col>
            <Col md="4" sm="6" xs="6">
            <div className="refimg">
              <figure>
              <Img fluid={canss3} alt="Bireysel Web Tasarım 3" className="" />
              </figure>
            </div>
            </Col>
          </Row>
        </Col>
          </Row>
   </div>


           <div className="opacityblock">
       <Row>
         <Col md="5">
           <div className="numberheighlight"> <Img fluid={logoertek} alt="Avukat logo" className="" />
           </div>
           <h4>Ertek Avukatlık Bürosu</h4>
           <p className="alpha-color">Effect Aluminyum için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
     <p>Adres : www.erteklaw.com<br />
     Firma Lokasyonu : İstanbul</p>    </Col>
         <Col md="7">
           <Row>

             <Col md="4">
             <div className="refimg">
               <figure>
                 <Img fluid={ertekss1} alt="Avukat Web Tasarım 1" className="" />
               </figure>
             </div>
             </Col>
             <Col md="4" sm="6" xs="6">
             <div className="refimg">
               <figure>
                 <Img fluid={ertekss2} alt="Avukat Web Tasarım 2" className="" />
               </figure>
             </div>
             </Col>
             <Col md="4" sm="6" xs="6">
             <div className="refimg">
               <figure>
               <Img fluid={ertekss3} alt="Avukat Web Tasarım 3" className="" />
               </figure>
             </div>
             </Col>
           </Row>
         </Col>
           </Row>
    </div>


            <div className="opacityblock">
        <Row>
          <Col md="5">
            <div className="numberheighlight"> <Img fluid={logokarayaka} alt="Karayaka Metal logo" className="" />
            </div>
            <h4>Karayaka Metal</h4>
            <p className="alpha-color">Karayaka Metal için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
      <p>Adres : www.karayakametal.com<br />
      Firma Lokasyonu : Kocaeli</p>    </Col>
          <Col md="7">
            <Row>

              <Col md="4">
              <div className="refimg">
                <figure>
                  <Img fluid={karayakass1} alt="Karayaka Metal Web Tasarım 1" className="" />
                </figure>
              </div>
              </Col>
              <Col md="4" sm="6" xs="6">
              <div className="refimg">
                <figure>
                  <Img fluid={karayakass2} alt="Karayaka Metal Web Tasarım 2" className="" />
                </figure>
              </div>
              </Col>
              <Col md="4" sm="6" xs="6">
              <div className="refimg">
                <figure>
                <Img fluid={karayakass3} alt="Karayaka Metal Web Tasarım 3" className="" />
                </figure>
              </div>
              </Col>
            </Row>
          </Col>
            </Row>
     </div>


             <div className="opacityblock">
         <Row>
           <Col md="5">
             <div className="numberheighlight"> <Img fluid={logomesa} alt="Mesa Reklam Logo" className="" />
             </div>
             <h4>Mesa Reklam</h4>
             <p className="alpha-color">Mesa Reklam için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
       <p>Adres : www.mesareklam.com.tr<br />
       Firma Lokasyonu : Ankara</p>    </Col>
           <Col md="7">
             <Row>

               <Col md="4">
               <div className="refimg">
                 <figure>
                   <Img fluid={mesass1} alt="Mesa Reklam Web Tasarım 1" className="" />
                 </figure>
               </div>
               </Col>
               <Col md="4" sm="6" xs="6">
               <div className="refimg">
                 <figure>
                   <Img fluid={mesass2} alt="Mesa Reklam Web Tasarım 2" className="" />
                 </figure>
               </div>
               </Col>
               <Col md="4" sm="6" xs="6">
               <div className="refimg">
                 <figure>
                 <Img fluid={mesass3} alt="Mesa Reklam Web Tasarım 3" className="" />
                 </figure>
               </div>
               </Col>
             </Row>
           </Col>
             </Row>
      </div>


              <div className="opacityblock">
          <Row>
            <Col md="5">
              <div className="numberheighlight"> <Img fluid={logomotor} alt="Motorlu Perde Logo" className="" />
              </div>
              <h4>Motorlu Perde Sistemleri</h4>
              <p className="alpha-color">Effect Aluminyum için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
        <p>Adres : www.motorluperdesistemleri.net<br />
        Firma Lokasyonu : İstanbul</p>    </Col>
            <Col md="7">
              <Row>

                <Col md="4">
                <div className="refimg">
                  <figure>
                    <Img fluid={motorluss1} alt="Motorlu Perde Web Tasarım 1" className="" />
                  </figure>
                </div>
                </Col>
                <Col md="4" sm="6" xs="6">
                <div className="refimg">
                  <figure>
                    <Img fluid={motorluss2} alt="Motorlu Perde Web Tasarım 2" className="" />
                  </figure>
                </div>
                </Col>
                <Col md="4" sm="6" xs="6">
                <div className="refimg">
                  <figure>
                  <Img fluid={motorluss3} alt="Motorlu Perde Web Tasarım 3" className="" />
                  </figure>
                </div>
                </Col>
              </Row>
            </Col>
              </Row>
       </div>
       <div className="opacityblock">
   <Row>
     <Col md="5">
       <div className="numberheighlight"> <Img fluid={logoofis} alt="Ofis Tabela Logo" className="" />
       </div>
       <h4>Ofis Tabela</h4>
       <p className="alpha-color">Ofis Tabela için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
 <p>Adres : www.ofis-tabela.com<br />
 Firma Lokasyonu : Ankara</p>    </Col>
     <Col md="7">
       <Row>

         <Col md="4">
         <div className="refimg">
           <figure>
             <Img fluid={ofistabelass1} alt="Ofis Tabela Web Tasarım" className="" />
           </figure>
         </div>
         </Col>
         <Col md="4" sm="6" xs="6">
         <div className="refimg">
           <figure>
             <Img fluid={ofistabelass1} alt="Ofis Tabela Web Tasarım" className="" />
           </figure>
         </div>
         </Col>
         <Col md="4" sm="6" xs="6">
         <div className="refimg">
           <figure>
           <Img fluid={ofistabelass1} alt="Ofis Tabela Web Tasarım" className="" />
           </figure>
         </div>
         </Col>
       </Row>
     </Col>
       </Row>
</div>
<div className="opacityblock">
<Row>
<Col md="5">
<div className="numberheighlight"> <Img fluid={logomotor} alt="Sahne Perde Logo" className="" />
</div>
<h4>Sahne Perde Sistemleri</h4>
<p className="alpha-color">Sahne Perde Sistemleri için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
<p>Adres : www.sahneperdesistemleri.net<br />
Firma Lokasyonu : İstanbul</p>    </Col>
<Col md="7">
<Row>

  <Col md="4">
  <div className="refimg">
    <figure>
      <Img fluid={sahness1} alt="Sahne perdesi web tasarım 1" className="" />
    </figure>
  </div>
  </Col>
  <Col md="4" sm="6" xs="6">
  <div className="refimg">
    <figure>
      <Img fluid={sahness2} alt="Sahne perdesi web tasarım 2" className="" />
    </figure>
  </div>
  </Col>
  <Col md="4" sm="6" xs="6">
  <div className="refimg">
    <figure>
    <Img fluid={sahness3} alt="Sahne perdesi web tasarım 3" className="" />
    </figure>
  </div>
  </Col>
</Row>
</Col>
</Row>
</div>
<div className="opacityblock">
<Row>
<Col md="5">
<div className="numberheighlight"> <Img fluid={logoturkbil} alt="Logo Turkbil" className="" />
</div>
<h4>Türkbil</h4>
<p className="alpha-color">Turkbil için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
<p>Adres : www.turkbil.com.tr<br />
Firma Lokasyonu : İzmir</p>    </Col>
<Col md="7">
<Row>

  <Col md="4">
  <div className="refimg">
    <figure>
      <Img fluid={turkbilss1} alt="Turkbil Web Tasarım 1" className="" />
    </figure>
  </div>
  </Col>
  <Col md="4" sm="6" xs="6">
  <div className="refimg">
    <figure>
      <Img fluid={turkbilss2} alt="Turkbil Web Tasarım 2" className="" />
    </figure>
  </div>
  </Col>
  <Col md="4" sm="6" xs="6">
  <div className="refimg">
    <figure>
    <Img fluid={turkbilss3} alt="Turkbil Web Tasarım 3" className="" />
    </figure>
  </div>
  </Col>
</Row>
</Col>
</Row>
</div>
<div className="opacityblock">
<Row>
<Col md="5">
<div className="numberheighlight"> <Img fluid={logowinsa} alt="Winsa Ankara Logo" className="" />
</div>
<h4>Winsa Ankara</h4>
<p className="alpha-color">Çolakoğlu Winsa Ankara için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
<p>Adres : www.winsaankara.com<br />
Firma Lokasyonu : Ankara</p>    </Col>
<Col md="7">
<Row>

  <Col md="4">
  <div className="refimg">
    <figure>
      <Img fluid={winsass1} alt="Winsa Ankara Web Tasarım 1" className="" />
    </figure>
  </div>
  </Col>
  <Col md="4" sm="6" xs="6">
  <div className="refimg">
    <figure>
      <Img fluid={winsass2} alt="Winsa Ankara Web Tasarım 2" className="" />
    </figure>
  </div>
  </Col>
  <Col md="4" sm="6" xs="6">
  <div className="refimg">
    <figure>
    <Img fluid={winsass3} alt="Winsa Ankara Web Tasarım 3" className="" />
    </figure>
  </div>
  </Col>
</Row>
</Col>
</Row>
</div>

			          </Container>
  			        </section>


  </Layout>
);
}
}

export const pageQuery = graphql`
query {
logocontrol: file(
 relativePath: { regex: "/ss/logocontrol.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
control1: file(
 relativePath: { regex: "/ss/controlss1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
control2: file(
 relativePath: { regex: "/ss/controlss2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
control3: file(
 relativePath: { regex: "/ss/controlss3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logoanadolu: file(
 relativePath: { regex: "/ss/logoanadolu.png/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
anadoluses1: file(
 relativePath: { regex: "/ss/anadoluss1.jpg/" }
) {
childImageSharp {
        fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
anadoluses2: file(
 relativePath: { regex: "/ss/anadoluss2.jpg/" }
) {
childImageSharp {
        fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
anadoluses3: file(
 relativePath: { regex: "/ss/anadoluss3.jpg/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logoeffect: file(
 relativePath: { regex: "/ss/logoeffect.png/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
effect1: file(
 relativePath: { regex: "/ss/effectss1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
effect2: file(
 relativePath: { regex: "/ss/effectss2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
effect3: file(
 relativePath: { regex: "/ss/effectss3.jpg/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logogaye: file(
 relativePath: { regex: "/ss/logogaye.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
gaye1: file(
 relativePath: { regex: "/ss/gayess1.jpg/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
gaye2: file(
 relativePath: { regex: "/ss/gayess2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
gaye3: file(
 relativePath: { regex: "/ss/gayess3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logobula: file(
 relativePath: { regex: "/ss/logobula.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
bulass1: file(
 relativePath: { regex: "/ss/bulass1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
bulass2: file(
 relativePath: { regex: "/ss/bulass2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
bulass3: file(
 relativePath: { regex: "/ss/bulass3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logoertek: file(
 relativePath: { regex: "/ss/logoertek.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
ertekss1: file(
 relativePath: { regex: "/ss/ertekss1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
ertekss2: file(
 relativePath: { regex: "/ss/ertekss2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
ertekss3: file(
 relativePath: { regex: "/ss/ertekss3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logocan: file(
 relativePath: { regex: "/ss/logocan.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
canss1: file(
 relativePath: { regex: "/ss/canss1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
canss2: file(
 relativePath: { regex: "/ss/canss2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
canss3: file(
 relativePath: { regex: "/ss/canss3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logokarayaka: file(
 relativePath: { regex: "/ss/logokarayaka.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
karayakass1: file(
 relativePath: { regex: "/ss/karayakass1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
karayakass2: file(
 relativePath: { regex: "/ss/karayakass2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
karayakass3: file(
 relativePath: { regex: "/ss/karayakass3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logomesa: file(
 relativePath: { regex: "/ss/logomesa.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
mesass1: file(
 relativePath: { regex: "/ss/mesass1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
mesass2: file(
 relativePath: { regex: "/ss/mesass2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
mesass3: file(
 relativePath: { regex: "/ss/mesass3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logomotor: file(
 relativePath: { regex: "/ss/logomotor.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
motorluss1: file(
 relativePath: { regex: "/ss/motorluss1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
motorluss2: file(
 relativePath: { regex: "/ss/motorluss2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
motorluss3: file(
 relativePath: { regex: "/ss/motorluss3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logoofis: file(
 relativePath: { regex: "/ss/logoofis.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
ofistabelass1: file(
 relativePath: { regex: "/ss/ofistabelass1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
sahness1: file(
 relativePath: { regex: "/ss/sahness1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
sahness2: file(
 relativePath: { regex: "/ss/sahness2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
sahness3: file(
 relativePath: { regex: "/ss/sahness3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logoturkbil: file(
 relativePath: { regex: "/ss/logoturkbil.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
turkbilss1: file(
 relativePath: { regex: "/ss/turkbilss1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
turkbilss2: file(
 relativePath: { regex: "/ss/turkbilss2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
turkbilss3: file(
 relativePath: { regex: "/ss/turkbilss3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logowinsa: file(
 relativePath: { regex: "/ss/logowinsa.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
winsass1: file(
 relativePath: { regex: "/ss/winsass1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
winsass2: file(
 relativePath: { regex: "/ss/winsass2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
winsass3: file(
 relativePath: { regex: "/ss/winsass3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
pagebgref: file(
 relativePath: { regex: "/bg9.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
